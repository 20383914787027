import router from "@/router";


const base_url = process.env.VUE_APP_API_BASE_URL
import Axios  from "axios"
import store from "@/store";
const http = Axios.create({
    baseURL: base_url,
});

const noTokenRequest =  Axios.create({
    baseURL: base_url
});

noTokenRequest.interceptors.request.use(request => {
  request.headers['language-code'] = localStorage.getItem('lang')  || 'en'
  request.headers['Accept-Language'] = localStorage.getItem('lang')  || 'en'
  return request
})

http.interceptors.request.use((request ) => {
    const token = localStorage.getItem('token')
    request.headers['language-code'] = localStorage.getItem('lang')  || 'en'
    request.headers['Accept-Language'] = localStorage.getItem('lang')  || 'en'
    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }
    return request
})

http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error?.response?.data?.statusCode) {
    if (localStorage.getItem('token')) localStorage.removeItem('token')
    router.push({path: '/login'});
  }else if (500 === error?.response?.data?.statusCode){
    store.commit('auth/CHANGE_LOADER', false)
    error.response.data.message = 'Something went wrong';
    return Promise.reject(error)
  }
  else return Promise.reject(error);
});

export {http, noTokenRequest}
